import { onMounted, onUnmounted, ref } from 'vue'

export function useOnline() {
  const isOnline = ref(true)

  function updateOnlineStatus() {
    isOnline.value = navigator.onLine
  }

  onMounted(() => {
    isOnline.value = navigator.onLine

    window.addEventListener('offline', updateOnlineStatus)
    window.addEventListener('online', updateOnlineStatus)
  })

  onUnmounted(() => {
    window.removeEventListener('offline', updateOnlineStatus)
    window.removeEventListener('online', updateOnlineStatus)
  })

  return { isOnline }
}
