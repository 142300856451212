import type { AsyncComponentLoader, Component } from 'vue'
import {
  LazyAuthorizationForm,
  LazyBiometricModal,
  LazyBonusesWidgetMBonusesWidgetModal,
  LazyChangeNicknameForm,
  LazyChangeNicknameFormMChangeNicknameForm,
  LazyChangePasswordForm,
  LazyChangePasswordFormMChangePasswordForm,
  LazyChooseGameModal,
  LazyCookiesModal,
  LazyDeleteAddressModal,
  LazyDepositBonusMDepositBonusModal,
  LazyDepositBonusModal,
  LazyDepositBonusTooltip,
  LazyFreeBonusCardPartsFreebetTooltip,
  LazyFreeBonusCardPartsFreespinTooltip,
  LazyFreespinRollingMFreespinRollingModal,
  LazyFreespinRollingModal,
  LazyIpRestrictionModal,
  LazyMAuthorization,
  LazyMNewAddressModal,
  LazyMPaymentsModal,
  LazyMWithdrawalStatus,
  LazyNewAddressModal,
  LazyNotificationsListMNotificationsList,
  LazyPaymentsModal,
  LazyRecoveryPasswordForm,
  LazyRecoveryPasswordFormMRecoveryPassword,
  LazyReferralBonusTooltip,
  LazyRegistrationForm,
  LazyRegularBonusTooltip,
  LazySearchForm,
  LazySearchMSearchForm,
  LazySumSubModal,
  LazyVerificationModal,
  LazyWithdrawalStatus,
  LazyBetsHistoryMBetHistoryModal,
  LazyCashBonusTooltip,
  LazyPwaInstallModal,
  LazyUserVerificationModal,
  LazyPaymentsKycModal,
  LazyChangeVerificationDataModal,
} from '#components'

type Middleware = 'auth' | 'geo'

interface ModalConfig {
  component: AsyncComponentLoader | Component
  wrapper: 'modal' | 'drawer' | 'drawer-compact'
  closeOnClickOutside?: boolean
  hideCloseButton?: boolean
  closableWithSwipe?: boolean
  middleware?: Middleware[]
}

const desktopModals: Record<string, ModalConfig> = {
  register: {
    component: LazyRegistrationForm,
    wrapper: 'modal',
    closeOnClickOutside: false,
    middleware: ['geo'],
  },
  login: {
    component: LazyAuthorizationForm,
    wrapper: 'modal',
    middleware: ['geo'],
  },
  recoverPassword: {
    component: LazyRecoveryPasswordForm,
    wrapper: 'modal',
    middleware: ['geo'],
  },
  payments: {
    component: LazyPaymentsModal,
    closeOnClickOutside: false,
    wrapper: 'modal',
    middleware: ['auth', 'geo'],
  },
  paymentsKyc: {
    component: LazyPaymentsKycModal,
    wrapper: 'modal',
  },
  search: {
    component: LazySearchForm,
    wrapper: 'modal',
  },
  withdrawalStatus: {
    component: LazyWithdrawalStatus,
    wrapper: 'modal',
  },
  newAddress: {
    component: LazyNewAddressModal,
    wrapper: 'modal',
  },
  deleteAddress: {
    component: LazyDeleteAddressModal,
    wrapper: 'modal',
  },
  changeNickname: {
    component: LazyChangeNicknameForm,
    wrapper: 'modal',
  },
  changePersonal: {
    component: LazyChangeVerificationDataModal,
    wrapper: 'modal',
  },
  cookies: {
    component: LazyCookiesModal,
    wrapper: 'modal',
  },
  restriction: {
    component: LazyIpRestrictionModal,
    wrapper: 'modal',
    hideCloseButton: true,
    closeOnClickOutside: false,
  },
  verification: {
    component: LazyVerificationModal,
    wrapper: 'modal',
  },
  biometric: {
    component: LazyBiometricModal,
    wrapper: 'modal',
  },
  sumSubVerification: {
    component: LazySumSubModal,
    wrapper: 'modal',
  },
  changePassword: {
    component: LazyChangePasswordForm,
    wrapper: 'modal',
  },
  chooseGame: {
    component: LazyChooseGameModal,
    wrapper: 'modal',
  },
  freespinRolling: {
    component: LazyFreespinRollingModal,
    wrapper: 'modal',
  },
  depositBonus: {
    component: LazyDepositBonusModal,
    wrapper: 'modal',
  },
  freespinTooltip: {
    component: LazyFreeBonusCardPartsFreespinTooltip,
    wrapper: 'modal',
  },
  freebetTooltip: {
    component: LazyFreeBonusCardPartsFreebetTooltip,
    wrapper: 'modal',
  },
  regularBonusTooltip: {
    component: LazyRegularBonusTooltip,
    wrapper: 'modal',
  },
  referralBonusTooltip: {
    component: LazyReferralBonusTooltip,
    wrapper: 'modal',
  },
  depositBonusTooltip: {
    component: LazyDepositBonusTooltip,
    wrapper: 'modal',
  },
  cashBonusTooltip: {
    component: LazyCashBonusTooltip,
    wrapper: 'modal',
  },
  userVerification: {
    component: LazyUserVerificationModal,
    wrapper: 'modal',
    hideCloseButton: true,
    closeOnClickOutside: false,
  },
  pwaInstall: {
    component: LazyPwaInstallModal,
    wrapper: 'modal',
  },
}

const mobileModals: Record<string, ModalConfig> = {
  changePersonal: {
    component: LazyChangeVerificationDataModal,
    wrapper: 'drawer-compact',
  },
  pwaInstall: {
    component: LazyPwaInstallModal,
    wrapper: 'drawer-compact',
  },
  userVerification: {
    component: LazyUserVerificationModal,
    wrapper: 'drawer-compact',
    closableWithSwipe: false,
  },
  register: {
    component: LazyMAuthorization,
    wrapper: 'drawer-compact',
    middleware: ['geo'],
  },
  login: {
    component: LazyMAuthorization,
    wrapper: 'drawer-compact',
    middleware: ['geo'],
  },
  recoverPassword: {
    component: LazyRecoveryPasswordFormMRecoveryPassword,
    wrapper: 'drawer',
    middleware: ['geo'],
  },
  notify: {
    component: LazyNotificationsListMNotificationsList,
    wrapper: 'drawer',
  },
  changeNickname: {
    component: LazyChangeNicknameFormMChangeNicknameForm,
    wrapper: 'drawer-compact',
  },
  search: {
    component: LazySearchMSearchForm,
    wrapper: 'drawer',
  },
  payments: {
    component: LazyMPaymentsModal,
    wrapper: 'drawer',
    middleware: ['geo', 'auth'],
  },
  paymentsKyc: {
    component: LazyPaymentsKycModal,
    wrapper: 'drawer-compact',
  },
  newAddress: {
    component: LazyMNewAddressModal,
    wrapper: 'drawer',
  },
  withdrawalStatus: {
    component: LazyMWithdrawalStatus,
    wrapper: 'drawer-compact',
  },
  sumSubVerification: {
    component: LazySumSubModal,
    wrapper: 'drawer',
  },
  restriction: {
    component: LazyIpRestrictionModal,
    wrapper: 'drawer-compact',
  },
  biometric: {
    component: LazyBiometricModal,
    wrapper: 'drawer-compact',
  },
  verification: {
    component: LazyVerificationModal,
    wrapper: 'drawer-compact',
  },
  changePassword: {
    component: LazyChangePasswordFormMChangePasswordForm,
    wrapper: 'drawer-compact',
  },
  chooseGame: {
    component: LazyChooseGameModal,
    wrapper: 'drawer-compact',
  },
  freespinRolling: {
    component: LazyFreespinRollingMFreespinRollingModal,
    wrapper: 'drawer-compact',
  },
  depositBonus: {
    component: LazyDepositBonusMDepositBonusModal,
    wrapper: 'drawer-compact',
  },
  bonuses: {
    component: LazyBonusesWidgetMBonusesWidgetModal,
    wrapper: 'drawer-compact',
  },
  freespinTooltip: {
    component: LazyFreeBonusCardPartsFreespinTooltip,
    wrapper: 'drawer-compact',
  },
  freebetTooltip: {
    component: LazyFreeBonusCardPartsFreebetTooltip,
    wrapper: 'drawer-compact',
  },
  regularBonusTooltip: {
    component: LazyRegularBonusTooltip,
    wrapper: 'drawer-compact',
  },
  referralBonusTooltip: {
    component: LazyReferralBonusTooltip,
    wrapper: 'drawer-compact',
  },
  depositBonusTooltip: {
    component: LazyDepositBonusTooltip,
    wrapper: 'drawer-compact',
  },
  cashBonusTooltip: {
    component: LazyCashBonusTooltip,
    wrapper: 'drawer-compact',
  },
  bets: {
    component: LazyBetsHistoryMBetHistoryModal,
    wrapper: 'drawer',
    middleware: ['geo', 'auth'],
  },
}

export function useModalConfig() {
  const { isMobile } = usePlatform()
  return computed(() => (isMobile.value ? mobileModals : desktopModals))
}
