<template>
  <Teleport to="body">
    <Transition name="drawer">
      <div
        v-if="isActive"
        class="wrapper"
        data-t="st-drawer"
        :class="{
          'with-overlay': withOverlay,
        }"
      >
        <div
          id="drawer-container"
          ref="container"
          class="container"
          :class="{
            animated: !isSwiping,
            full: fullScreen,
            'with-shadow': withShadow,
          }"
          :style="{ transform: `translateY(${top})` }"
          data-t="drawer-container-2xdy"
        >
          <div
            ref="dragLine"
            :class="isEmbededDragLine ? 'drag-line-embeded' : 'drag-line'"
          >
            <div class="line" />
          </div>
          <div class="content">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useSwipe } from '@st/use/composables'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    fullScreen?: boolean
    withOverlay?: boolean
    isEmbededDragLine?: boolean
    withShadow?: boolean
    closableWithSwipe?: boolean
  }>(),
  {
    fullScreen: true,
    withOverlay: true,
    isEmbededDragLine: false,
    withShadow: false,
    closableWithSwipe: true,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const dragLine = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)
const offsetHeight = computed(() => container.value?.offsetHeight)
const top = ref('0')

const isSwipingScrollable = ref(false)
const { isSwiping, lengthY } = useSwipe(container, {
  passive: true,
  threshold: 5,
  /**
   * Если элемент скроллится - может возникнуть конфликт скролла и свайпа
   * Чтобы этого избежать,мы проверяем всех родителей элемента, на котором начался свайп
   * И если хотя бы один из них скроллится - мы игнорируем свайп
   */
  onSwipeStart(e) {
    if (!props.closableWithSwipe) return
    // Был очень нужен преждевременный выход из цикла
    // eslint-disable-next-line no-restricted-syntax
    for (const node of e.composedPath()) {
      const el = node as Element

      /**
       * Останавливаем проверку тут, потому что дальше пойдут всякие body, html и тд
       */
      if (el.id === 'drawer-container') return

      if (el.scrollTop !== 0) {
        isSwipingScrollable.value = true
        return
      }
    }
  },
  onSwipe() {
    if (
      !props.closableWithSwipe ||
      isSwipingScrollable.value ||
      !offsetHeight.value
    ) {
      return
    }

    if (lengthY.value < 0) {
      const length = Math.abs(lengthY.value)
      top.value = `${length}px`
    } else {
      top.value = '0'
    }
  },
  onSwipeEnd() {
    if (!props.closableWithSwipe) return

    if (
      !isSwipingScrollable.value &&
      lengthY.value < 0 &&
      offsetHeight.value &&
      Math.abs(lengthY.value) / offsetHeight.value >= 0.1
    ) {
      isActive.value = false
    }
    top.value = '0'
    isSwipingScrollable.value = false
  },
})

function lockSwitcher(key: boolean) {
  if (typeof document !== 'undefined' && props.withOverlay) {
    if (key) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }
}

onMounted(() => {
  if (props.modelValue) {
    lockSwitcher(true)
  }
})

onUnmounted(() => {
  lockSwitcher(false)
})

watch(
  () => props.modelValue,
  (newValue) => {
    lockSwitcher(newValue)
  },
)
</script>

<style>
body {
  &:has(#drawer-container) {
    overflow: hidden;
    height: 100%;
  }
}
</style>

<style scoped>
.wrapper {
  pointer-events: none;

  position: fixed;
  z-index: 109;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding-top: var(--spacing-250);

  transition: opacity 0.2s;

  &.with-overlay {
    pointer-events: auto;
    background-color: rgb(36 38 43 / 64%);
  }
}

.container {
  pointer-events: auto;
  will-change: transform;

  position: relative;

  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  min-height: 0;

  &.full {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
  }

  &.animated {
    transition: transform 0.25s ease-in-out;
  }

  &.with-shadow {
    box-shadow: 0 -20px 32px 0 rgb(0 0 0 / 24%);
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  min-height: 0;

  background-color: var(--background-base);
}

.drag-line {
  display: flex;
  flex: 0 0 20px;
  align-items: center;
  justify-content: center;

  background: var(--background-base);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.drag-line-embeded {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;

  padding: var(--spacing-100);

  background-color: transparent;

  ~ .content {
    border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
  }
}

.line {
  width: 36px;
  height: 4px;
  background-color: rgb(255 255 255 / 24%);
  border-radius: var(--border-radius-050);
}

.drawer-enter-active,
.drawer-leave-active {
  .container {
    transition: transform 0.5s;
  }

  &.with-overlay {
    transition: background-color 0.5s;
  }
}

.drawer-enter-from,
.drawer-leave-to {
  .container {
    transform: translateY(100%) !important;
  }

  &.with-overlay {
    background-color: rgba(0 0 0 / 0%);
  }
}
</style>
