<template>
  <NuxtLink v-bind="nuxtLinkProps" :to="preparedRoute">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { omit } from '@st/utils'
import type { RouteLocationRaw } from 'vue-router'
import type { NuxtLinkProps } from '#app'
import { usePrefixRoute } from '../composables/usePrefixRoute'

interface Props extends NuxtLinkProps {
  locale?: string
  to: RouteLocationRaw
  platform?: 'mobile' | 'desktop'
}

const props = defineProps<Props>()
const prefixRoute = usePrefixRoute()

const nuxtLinkProps = computed(() =>
  omit(props, ['locale', 'to', 'noPrefetch']),
)

const preparedRoute = computed(() => {
  const { to, locale, platform } = props
  return prefixRoute(to, { locale, platform })
})
</script>
