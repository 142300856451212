export function useApplicationType() {
  const { $pwa } = useNuxtApp()
  const telegram = (globalThis as any)?.Telegram?.WebApp

  const isTelegramMiniApp = computed(() =>
    telegram ? Object.keys(telegram.initDataUnsafe).length !== 0 : false,
  )
  const isPwa = computed(() => !!($pwa as any)?.isPWAInstalled)
  const isWeb = computed(() => !isPwa.value && !isTelegramMiniApp.value)

  return {
    isTelegramMiniApp,
    isPwa,
    isWeb,
  }
}
