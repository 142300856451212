import dayjs, { type ConfigType, type Dayjs } from 'dayjs'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import durationPlugin, { type Duration } from 'dayjs/plugin/duration'
import utcPlugin from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'

export { type Dayjs, type Duration }

dayjs.extend(localizedFormatPlugin)
dayjs.extend(durationPlugin)
dayjs.extend(utcPlugin)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)

export type ParseDateFactory = (...args: Parameters<typeof dayjs>) => Dayjs
export type DurationFactory = typeof dayjs.duration
export type IsDurationFactory = typeof dayjs.isDuration
export type ParseDateTZFactory = (
  date: ConfigType,
  format?: string,
  timeZone?: string,
) => Dayjs
export type GuessFactory = typeof dayjs.tz.guess

export interface UseDateReturn {
  /**
   * Creates a dayjs object
   * @see https://day.js.org/docs/en/parse/parse
   * @example
   * parseDate('2018-04-04T16:00:00.000Z').add(1, 'day').format('L')
   */
  parseDate: ParseDateFactory
  /**
   * Creates a duration object
   * @see https://day.js.org/docs/en/durations/creating
   * @example
   * duration(4, 'hours').asMilliseconds()
   */
  duration: DurationFactory
  /**
   * Check is duration valid object
   * @see https://day.js.org/docs/en/durations/is-a-duration#docsNav
   * @example
   * isDuration(duration(4112))
   */
  isDuration: IsDurationFactory
}

/**
 * Хук для работы с датами. Основан на dayjs
 * @see https://day.js.org/docs/en
 */
export function useDate(locale = useI18n()?.locale): UseDateReturn {
  function parseDate(...args: Parameters<typeof dayjs>): Dayjs {
    return dayjs(...args)
      .tz()
      .locale(toValue(locale))
  }

  return {
    parseDate,
    duration: dayjs.duration,
    isDuration: dayjs.isDuration,
  }
}
