import { useLocalStorage } from '@st/use/composables'
import type { SourceTypes, StartupResult, IpqsStorage } from '../types'
import { useIpqsScript } from './useIpqsScript'

export function useIpqs() {
  const stFetch = useRawStFetch()

  const ipqsStorage = useLocalStorage<IpqsStorage>('ipqsStorage', {
    ipqsId: '',
    ipqsGuid: '',
  })

  const { onLoaded } = useIpqsScript()

  async function postFingerPrintData(id: string, source: SourceTypes) {
    const body = {
      d: id,
      s: source,
    }

    await stFetch('/ne', {
      method: 'post',
      body,
    })
  }

  function sendFingerPrintData(userId: string) {
    if (!userId) return

    onLoaded(({ Store, AfterResult, Resume }) => {
      Store('id', userId)

      /* 
        если слепок фингерпринта уже есть на клиенте
        отправляем на бэк, без запуска Startup(ipqs) сервиса
      */
      if (ipqsStorage.value.ipqsId && ipqsStorage.value.ipqsGuid) {
        postFingerPrintData(ipqsStorage.value.ipqsId, 'web')
        postFingerPrintData(ipqsStorage.value.ipqsGuid, 'web2')

        return
      }
      /**
       * callback срабатывает только один раз и возвращает device_id, guid
       * после возобновления Startup.Resume()
       */
      AfterResult((result: StartupResult) => {
        const { device_id: deviceId, guid } = result

        /* 
          сохраняем все слепки в localStorage
        */
        ipqsStorage.value.ipqsId = deviceId
        ipqsStorage.value.ipqsGuid = guid

        if (deviceId && guid) {
          postFingerPrintData(deviceId, 'web')
          postFingerPrintData(guid, 'web2')
        }
      })

      Resume()
    })
  }

  return {
    sendFingerPrintData,
  }
}
