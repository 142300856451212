<template>
  <NuxtLayout :name="isMobile ? 'mobile' : 'default'">
    <div class="error-page" :class="{ mobile: isMobile }">
      <img v-if="Error" class="error" :src="Error" alt="error" />
      <h2 v-else>{{ error?.statusCode }}</h2>
      <div class="error-wrapper">
        <h3 class="title">{{ t('errorPage.title') }}</h3>
        <p class="info">{{ t('errorPage.info') }}</p>
        <StButton to="/">{{ t('errorPage.button') }}</StButton>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import Error from './img/error.png'

const { t } = useI18n()
const { isMobile } = usePlatform()

useHead({
  title: t('pages.error'),
})
const props = defineProps({
  error: Object as () => NuxtError,
})
console.error(props.error?.statusCode, props.error)
</script>

<style scoped>
a {
  all: unset;
  cursor: pointer;
}

.error-page,
.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: var(--spacing-800);
  padding: 0 var(--spacing-200);

  * {
    margin: 0;
  }
}

.error {
  width: 100%;
  max-width: 578px;
  filter: drop-shadow(0 16px 120px rgb(254 94 1 / 32%));
}

.title {
  font: var(--desktop-text-2xl-semibold);
  text-align: center;
}

.info {
  margin-bottom: var(--spacing-300);
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.mobile {
  .title {
    margin-bottom: var(--spacing-100);
    font: var(--mobile-title-1-semibold);
  }

  .info {
    font: var(--mobile-text-content-regular);
  }
}
</style>
