import { useIsOnNuxtReady } from '@st/use/composables'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var _mtm: unknown[]
}

export function useAnalyticsScript() {
  const { isNuxtReady } = useIsOnNuxtReady()
  const config = useRuntimeConfig()
  const scriptUrl = config.public.matomoScriptUrl

  const matomo = useScript(
    {
      src: scriptUrl,
      async: true,
      crossorigin: false,
      referrerpolicy: false,
    },
    {
      trigger: computed(() => isNuxtReady.value && !!scriptUrl),
      // eslint-disable-next-line no-underscore-dangle
      use: () => globalThis._mtm,
    },
  )

  matomo.onLoaded((mtm) => {
    mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  })

  matomo.onError((err) => {
    console.error(`Analytics script not loaded: ${err}`)
  })

  return matomo
}
