import type { Startup } from '../types'
import { useIpqsScript } from '../composables/useIpqsScript'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Startup: Startup
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const apiKey = config.public.ipqsKey

  if (!apiKey) {
    console.error('NUXT_PUBLIC_IPQS_KEY is missing!')
    return
  }

  const { onLoaded, onError } = useIpqsScript()

  onLoaded(({ Pause }) => {
    Pause()
  })

  onError((error) => {
    console.error('Failed to load Ipqs', error)
  })
})
