<template>
  <div :class="{ mobile: isMobile }" class="offline-page" data-t="offline-page">
    <svg
      class="broadcast"
      width="48"
      height="48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.272 4.444a2 2 0 0 1 0 2.828c-7.03 7.03-7.03 18.426 0 25.456a2 2 0 0 1-2.828 2.828c-8.592-8.591-8.592-22.52 0-31.112a2 2 0 0 1 2.828 0Zm25.457 0a2 2 0 0 1 2.828 0c8.592 8.591 8.592 22.52 0 31.112a2 2 0 0 1-2.828-2.828c7.03-7.03 7.03-18.426 0-25.456a2 2 0 0 1 0-2.828Zm-19.8 5.656a2 2 0 0 1 0 2.829c-3.905 3.905-3.905 10.237 0 14.142a2 2 0 0 1-2.828 2.828c-5.467-5.467-5.467-14.331 0-19.799a2 2 0 0 1 2.828 0Zm14.143 0a2 2 0 0 1 2.828 0c5.467 5.468 5.467 14.332 0 19.8a2 2 0 1 1-2.828-2.829c3.905-3.905 3.905-10.237 0-14.142a2 2 0 0 1 0-2.829ZM18 20a6 6 0 1 1 8 5.659V42a2 2 0 1 1-4 0V25.659A6.003 6.003 0 0 1 18 20Z"
        fill="#fff"
      />
    </svg>
    <h1 class="title">{{ t('offline.title') }}</h1>
    <p class="description">{{ t('offline.description') }}</p>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = usePlatform()
</script>

<style scoped>
.offline-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: var(--background-base);
}

.broadcast {
  margin-bottom: var(--spacing-500);
}

.title,
.description {
  margin: 0;
  text-align: center;
}

.title {
  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
}

.description {
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
}

.mobile {
  .broadcast {
    margin-bottom: var(--spacing-400);
  }

  .title {
    font: var(--mobile-title-2-semibold);
  }

  .description {
    font: var(--mobile-text-content-regular);
  }
}
</style>
