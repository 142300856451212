export default {
  "casino-game-layout": () => import("/app/apps/site/layouts/casino-game-layout.vue"),
  "casino-page": () => import("/app/apps/site/layouts/casino-page.vue"),
  default: () => import("/app/apps/site/layouts/default.vue"),
  "empty-restriction": () => import("/app/apps/site/layouts/empty-restriction.vue"),
  empty: () => import("/app/apps/site/layouts/empty.vue"),
  "event-layout": () => import("/app/apps/site/layouts/event-layout.vue"),
  "mobile-basic": () => import("/app/apps/site/layouts/mobile-basic.vue"),
  "mobile-casino-game": () => import("/app/apps/site/layouts/mobile-casino-game.vue"),
  "mobile-casino-main": () => import("/app/apps/site/layouts/mobile-casino-main.vue"),
  "mobile-casino-my-games": () => import("/app/apps/site/layouts/mobile-casino-my-games.vue"),
  "mobile-event": () => import("/app/apps/site/layouts/mobile-event.vue"),
  "mobile-with-footer-without-tapbar": () => import("/app/apps/site/layouts/mobile-with-footer-without-tapbar.vue"),
  "mobile-with-footer": () => import("/app/apps/site/layouts/mobile-with-footer.vue"),
  mobile: () => import("/app/apps/site/layouts/mobile.vue"),
  personal: () => import("/app/apps/site/layouts/personal.vue")
}