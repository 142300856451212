<template>
  <div class="notification-wrapper" data-t="notifications-manager">
    <TransitionGroup name="list" class="notifications" tag="div">
      <NotificationItem
        v-for="item in notificationsAlerts"
        :key="item.id"
        :item="item"
        compact
        class="notification-item"
        data-t="notification-ph1p"
      />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import NotificationItem from '../NotificationsList/parts/NotificationItem.vue'
import { useNotificationsStore } from '../../stores/useNotificationsStore'

const { notificationsAlerts } = storeToRefs(useNotificationsStore())
</script>

<style scoped>
.notification-wrapper {
  pointer-events: none;

  position: absolute;
  z-index: 10000;
  top: var(--spacing-200);
  right: 0;

  width: 360px;

  @media screen and (width <= 1024px) {
    right: 0;
    left: 0;
  }
}

.notification-item {
  pointer-events: all;
  margin-bottom: var(--spacing-100);
  transition: 0.3s;
}

.notifications {
  position: fixed;
  width: 360px;

  @media screen and (width <= 1024px) {
    width: 100%;
    min-width: 328px;
    padding: 0 var(--spacing-200);

    .notification-item {
      background: var(--background-tertiary);

      &:nth-child(n + 3) {
        display: none;
      }
    }

    .notification-item ~ .notification-item {
      transform: translateY(-110%) scale(0.95);
      background: var(--background-secondary);
    }

    .notification-item:first-child:not(:last-child) {
      z-index: 10;
      transform: translateY(12px);
      background: var(--background-tertiary);
      box-shadow:
        0 8px 24px -8px rgb(57 57 70 / 40%),
        0 4px 24px 0 rgb(17 17 24 / 80%);
    }
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.list-enter-from {
  transform: translateY(-16px);
  opacity: 0;
}

.list-leave-to {
  opacity: 0;
}

.list-leave-active:not(:first-child) {
  position: absolute;
}
</style>
